import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { useSiteSearchIndex } from "../hooks/searchIndex"
import Search from "../components/search"
import SmallTitleImage from "../components/smallTitleImage"

const NotFoundPage = () => {
  const { index } = useSiteSearchIndex()
  
  return (
      <Layout>
        <SEO title="404: Not found" />
        <div id="title" style={{ width: `100%`, marginBottom: 0, paddingBottom: 0 }}>
          <SmallTitleImage className="title-image" filename="404.png"/>
          <div className="overlay" style={{ backgroundColor: `gray`, }}></div>
          <h1>The page you were looking for could not be found.</h1>
        </div>
        <div className="centered-content">
              <p>Let's try again.</p>
              <Search searchIndex={index} variant="outlined"/>
        </div>
      </Layout>
  )
}

export default NotFoundPage
